import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './SectionFooter.module.scss';

interface FooterProps {
  children: ReactNode;
  className?: string;
}

export function SectionFooter({
  children,
  className,
}: FooterProps): JSX.Element {
  return <div className={classNames(styles.footer, className)}>{children}</div>;
}
