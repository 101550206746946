import { Image as IImage } from '@features/pg-funnel/services/contentful/types';
import { Image } from '@features/shared/components/image';
import classNames from 'classnames';
import { EntryFields } from 'contentful';
import {
  ClubPageHeading,
  FontColor,
  H1,
  H2,
  H3,
  H4,
  H5,
} from 'src/features/shared/components/typography';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import useLang from 'utils/hooks/use-lang';
import styles from './SectionHeader.module.scss';
import { SpecialHeading } from 'src/features/shared/components/typography/TypographyComponents';

export enum TitleTag {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  clubPageHeading = 'clubPageHeading',
  specialHeading = 'specialHeading',
}

const titleComponentMap = {
  [TitleTag.h1]: H1,
  [TitleTag.h2]: H2,
  [TitleTag.h3]: H3,
  [TitleTag.h4]: H4,
  [TitleTag.h5]: H5,
  [TitleTag.clubPageHeading]: ClubPageHeading,
  [TitleTag.specialHeading]: SpecialHeading,
};

interface SectionHeaderProps {
  headerImage?: IImage;
  image?: IImage;
  title?: string;
  titleStyle?: string;
  titleTag?: keyof typeof titleComponentMap;
  hasBorder?: boolean;
  isCentered?: boolean;
  description?: string | EntryFields.RichText;
  hasLightText?: boolean;
  className?: string;
}

export function SectionHeader({
  headerImage,
  image,
  title,
  titleTag = TitleTag.h2,
  hasBorder,
  titleStyle,
  description,
  isCentered,
  hasLightText,
  className,
}: SectionHeaderProps) {
  const { lang } = useLang();
  const { renderSectionDescription } = useRichTextRenderer(lang);
  const TitleComponent = titleComponentMap[titleTag];
  return (
    <>
      {headerImage && (
        <Image className={styles['header-image']} image={headerImage} />
      )}
      {title && (
        <>
          <div className={classNames(styles.header, className)}>
            {image && <Image className={styles.image} image={image} />}
            <TitleComponent
              data-content={title}
              typographyStyle={titleStyle}
              hasBorderTop={hasBorder}
              isCentered={isCentered}
              color={hasLightText && FontColor.Neutral06}
              className={styles.title}
            >
              {title}
            </TitleComponent>
          </div>
        </>
      )}

      {description && renderSectionDescription(description, styles.description)}
    </>
  );
}
