import classNames from 'classnames';
import { Layout } from 'features/shared/components/layout';
import { Section } from 'features/shared/components/layout/section';
import { SectionFooter } from 'features/shared/components/section-footer';
import { IClub } from 'src/features/club-page/types/IClub';
import { Link } from 'src/features/shared/components/link';
import { SectionButton } from 'src/features/shared/components/sections/section-button/SectionButton';
import { SectionHeader } from 'src/features/shared/components/sections/section-header';
import { ISection } from 'src/features/shared/contentful/types/ISection';
import { ButtonDisplayAs } from 'src/features/shared/utils/constants';
import styles from './index.module.scss';
import { TitleTag } from 'src/features/shared/components/sections/section-header/SectionHeader';

interface IContentfulSectionProps {
  item: ISection;
  club: IClub;
  stickToTop: boolean;
  stickToBottom: boolean;
}

export const ContentfulSection = ({
  item,
  club,
  stickToBottom,
  stickToTop,
}: IContentfulSectionProps) => {
  const isClubPage = club !== undefined;
  const isAlignedCenter = item.textPosition
    ? item.textPosition === 'Center'
    : true;
  const getSectionHeader = () => {
    if (!item.link || !item.linkImage) {
      return (
        <>
          <SectionHeader
            titleTag={
              item.hasTitleDecoration
                ? TitleTag.specialHeading
                : TitleTag.clubPageHeading
            }
            titleStyle={
              item.hasTitleDecoration
                ? TitleTag.specialHeading
                : TitleTag.clubPageHeading
            }
            title={item.title}
            description={item.description}
            headerImage={item.image}
            hasBorder
          />
        </>
      );
    }
    return (
      <Link href={item.link.href}>
        <SectionHeader
          title={item.title}
          description={item.description}
          headerImage={item.image}
          hasBorder
        />
      </Link>
    );
  };

  return (
    <Section hasNoBottomMargin={stickToBottom}>
      <Layout.Container
        backgroundColor={item.backgroundColor}
        stickToBottom={stickToBottom}
        stickToTop={stickToTop}
      >
        <Layout.Row>
          <Layout.Col
            columns={`xs:12 ${
              isClubPage ? 'md:6' : isAlignedCenter ? 'sm:10 md:8' : 'md:10'
            }`}
            offset={isAlignedCenter ? 'sm:1 md:2' : ''}
          >
            <div
              className={classNames(styles.content, {
                [styles.isLeftAligned]: !isAlignedCenter,
              })}
            >
              {getSectionHeader()}
              {(item.link || item.secondaryLink) && (
                <SectionFooter className={styles.footer}>
                  {item.link && (
                    <SectionButton
                      link={item.link}
                      eventName="click_cta_section"
                      label={item.link.label || item.label || ''}
                      displayAs={
                        item.displayAs || ButtonDisplayAs.PrimaryButton
                      }
                    />
                  )}
                  {item.secondaryLink && (
                    <SectionButton
                      label={item.secondaryLinkLabel || ''}
                      link={item.secondaryLink}
                      displayAs={
                        item.secondaryLinkDisplayAs ||
                        ButtonDisplayAs.SecondaryButton
                      }
                      eventName="click_secondary_cta_section"
                    />
                  )}
                </SectionFooter>
              )}
            </div>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
};
